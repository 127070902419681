import React from "react";
import { Link } from 'gatsby-plugin-react-i18next'
import { Box, Container, Flex, Heading, Button } from "@chakra-ui/react";
import RichText from "../PrismicRichText";

const CompletingOnline = ({ data }) => {
  return (
    <Container px="5" maxW="7xl" h="200px" my="5rem">
      <Flex
        boxShadow="0 22px 34px 0 rgba(0,0,0,0.15)"
        bgImage={`url(${data.bgImage})`}
        bgPos="center"
        bgSize="cover"
        bgRepeat="no-repeat"
        h="100%"
        justifyContent="space-between"
        borderRadius="24px"
        overflow="hidden"
        >
        <Flex
          flex="1"
          zIndex="1"
          direction="column"
          alignItems={{base: 'center', sm: "flex-start"}}
          justifyContent="center"
          px={{base: '4',sm: "5.875rem"}}
          position="relative">
          <Box
            position="absolute"
            left="0"
            top="0"
            height="100%"
            zIndex="10"
            w="full"
            background="linear-gradient(76.07deg, #006C49 30%, rgba(108,104,97, 0) 70%)"/>
          <Box
            zIndex="15"
            as="h3"
            fontSize={{base: 'sm', sm: "2xl"}}
            color="white"
            >
            <RichText text={data.description1}/>
          </Box>
          <Button
            as={Link}
            to="/sign-up"
            zIndex="15"
            variant="green" 
            w="220px" 
            mt="6" 
            >
            {data.buttonText}
          </Button>
        </Flex>
      </Flex>
    </Container>
  );
};

export default CompletingOnline;
