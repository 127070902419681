import React, { useState } from 'react';
import { Box, HStack, Image, Text } from '@chakra-ui/react';
import GreenComma from '../../images/double_comma_green.png';
import WhiteComma from '../../images/double_comma_white.png';

const CommentsCard = ({ value }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Box
      minH="13.75rem"
      maxW="35.75rem"
      borderRadius="24px"
      p="2rem"
      background={hovered ? 'green.200' : 'grey.200'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <HStack>
        <Image
          objectFit="cover"
          width={50}
          style={{
            height: 50,
          }}
          alt="reports"
          src={hovered ? WhiteComma : GreenComma}
        />
      </HStack>
      <Text fontSize="sm" color={hovered ? 'white' : 'green.200'} mt="6">
        {value?.text}
      </Text>
    </Box>
  );
};

export default CommentsCard;
