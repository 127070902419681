import {
  graphql,
  useStaticQuery,
} from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const usePrismicFinancialEducation =
  () => {
    const { language } = useI18next();
    const newLang =
      language === "en"
        ? "en-us"
        : language === "ru"
        ? "ru"
        : "hy-am";

    const response =
      useStaticQuery(graphql`
        query PrismicFinancialEducation {
          allPrismicFinancialeducation(
            filter: {
              tags: {
                in: "financial_education"
              }
            }
          ) {
            nodes {
              lang
              data {
                body {
                  ... on PrismicFinancialeducationDataBodyEventsAndUsefulLinks {
                    id
                    slice_type
                    primary {
                      id
                      slidetitle {
                        text
                      }
                      slidedescription {
                        richText
                      }
                      date
                      slideimage {
                        url
                      }
                      slide_image_thumbnail {
                        url
                      }
                      useful_link_title {
                        text
                      }
                      useful_link_description {
                        richText
                      }
                    }
                    items {
                      link_title
                      link_href {
                        url
                      }
                    }
                  }
                }
                navtext
                title {
                  text
                }
                bgimage {
                  url
                }
                buttontext
                buttontext1
                description {
                  richText
                }
                description1 {
                  richText
                }
                description2 {
                  richText
                }
                description3 {
                  richText
                }
                image {
                  url
                }
                informationbooklets {
                  image1 {
                    url
                  }
                  link {
                    url
                  }
                }
                links {
                  linktitle
                  src {
                    url
                  }
                  thumbnail {
                    url
                  }
                }
                links_1 {
                  linktitle
                  src {
                    url
                  }
                  thumbnail {
                    url
                  }
                }
                title1 {
                  text
                }
                title2 {
                  text
                }
                title3 {
                  text
                }
                title4 {
                  text
                }
                title5 {
                  text
                }
              }
            }
          }
        }
      `);
    return sanitize(
      response.allPrismicFinancialeducation.nodes.filter(
        (item) => item.lang === newLang
      )[0].data
    );
  };

const sanitize = (response) => {
  return {
    navText: response.navtext,
    title: response.title.text,
    title1: response.title1.text,
    title2: response.title2.text,
    title3: response.title3.text,
    title4: response.title4.text,
    title5: response.title5.text,
    description:
      response.description.richText,
    description1:
      response.description1.richText,
    description2:
      response.description2.richText,
    description3:
      response.description3.richText,
    buttonText: response.buttontext,
    buttonText_1: response.buttontext1,
    bgImage: response.bgimage.url,
    image: response.image.url,
    informationBooklets:
      response.informationbooklets.map(
        (informationBooklet) => ({
          link: informationBooklet.link
            .url,
          image:
            informationBooklet.image1
              .url,
        })
      ),
    slideData: response.body.map(
      (data) => ({
        id: data.primary.id,
        title:
          data.primary.slidetitle.text,
        description:
          data.primary.slidedescription
            .richText,
        date: data.primary.date,
        image:
          data.primary.slideimage.url,
        thumbnail:
          data.primary
            .slide_image_thumbnail.url,
        usefulLinks: {
          id: data.primary.id,
          title:
            data.primary
              .useful_link_title.text,
          description:
            data.primary
              .useful_link_description
              .richText,
          links: data.items.map(
            (link) => ({
              title: link.link_title,
              href: link.link_href.url,
            })
          ),
        },
      })
    ),
    links: response.links.map(
      (link) => ({
        title: link.linktitle,
        src: link.src.url,
        thumbnail: link.thumbnail.url,
      })
    ),
    links_1: response.links_1.map(
      (link_1) => ({
        title: link_1.linktitle,
        src: link_1.src.url,
        thumbnail: link_1.thumbnail.url,
      })
    ),
  };
};

export default usePrismicFinancialEducation;
