import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicWhatToExpect = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query usePrismicWhatToExpect {
       allPrismicWhattoexpect(filter: {tags: {in: "what_to_expect"}}) {
        nodes {
          lang
          data {
            header
            description {
              richText
            }
            title1 {
              text
            }
            description1 {
              richText
            }
            title2 {
              text
            }
            description2 {
              richText
            }
            title3 {
              text
            }
            description3 {
              richText
            }
            title4 {
              text
            }
            title5 {
              text
            }
            description4 {
              richText
            }
            statistictitle {
              text
            }
            statisticaldata {
              text
              maincolor
              secondarycolor
              icon {
                url
              }
              title_1 {
                text
              }
            }
            title {
              text
            }
            description5 {
              richText
            }
            commentstitle {
              text
            }
            comments {
              name
              text
              image5 {
                url
              }
              date
            }
            claimexaminationprocedureimage {
              url
            }
            image {
              url
            }
            image1 {
              url
            }
            image2 {
              url
            }
            image3 {
              url
            }
            image4 {
              url
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicWhattoexpect.nodes.filter(item => item.lang === newLang)[0].data);
};

const sanitize = (response) => {
    return {
        header: response.header,
        description: response.description.richText,
        regardless: {
            title: response.title1.text,
            description: response.description1.richText,
            image: response.image.url,
        },
        fair: {
            title: response.title2.text,
            description: response.description2.richText,
            image: response.image1.url,
        },
        fast: {
            title: response.title3.text,
            description: response.description3.richText,
            image: response.image2.url,
            claimExaminationProcedureImage: response.claimexaminationprocedureimage.url,
            claimExaminationProcedureTitle: response.title5.text

        },
        effective: {
            title: response.title4.text,
            description: response.description4.richText,
            image: response.image3.url,
        },
        professional: {
            title: response.title.text,
            description: response.description5.richText,
            image: response.image4.url,
        },
        statisticTitle: response.statistictitle.text,
        statisticalData: response.statisticaldata.map(statistic => ({
            text: statistic.text,
            mainColor: statistic.maincolor,
            secondaryColor: statistic.secondarycolor,
            icon: statistic.icon.url,
            title: statistic.title_1.text,
        })),
        commentsTitle: response.commentstitle.text,
        comments: response.comments.map(comment => ({
            name: comment.name,
            text: comment.text,
            date: comment.date,
            image: comment.image5.url,
        })),

    }
}

export default usePrismicWhatToExpect;