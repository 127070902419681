import React, { useState } from "react";
import {Box, Container, HStack, Stack, Button} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import LeftButton from "../../assets/arrow.svg";
import RightButton from "../../assets/right.svg";
// TODO: move imports form node_modules above
import {Link} from "gatsby-plugin-react-i18next";
import RichText from "../PrismicRichText";
import YoutubeFrames from "../Youtube";

const AboutOffice = React.forwardRef(({ data }, ref) => {
  const [tab, setTab] = useState(1);

  return (
    <Box ref={ref} overflow="hidden">
      <Container px="5" maxW="7xl" mt="6.25rem" mb="3.75rem">{/*mb="8.75rem" */}
        <HStack
          spacing="2.25rem"
          borderBottom="1px"
          color="border.100"
          pb="2rem"
        >
          <Box
            cursor="pointer"
            onClick={() => setTab(1)}
            as="span"
            fontSize={{ base: "md", sm: "2xl" }}
            fontWeight={tab === 1 ? "500" : "400"}
            borderRadius={"10px"}
            color={tab === 1 ? "white" : "green.200"}
            bg={tab === 1 ? "green.200": "white"}
            padding={"4px"}
            _hover={{
              bg:"green.200",
              color:"white",
              transform: "scale(1.05)",
              transition: "transform 0.3s ease, background-color 0.3s ease, color 0.3s ease" // Добавляем transition к bg и color
            }}
          >
            {data.title4}
          </Box>
          <Box
            cursor="pointer"
            onClick={() => setTab(2)}
            as="span"
            fontSize={{ base: "md", sm: "2xl" }}
            fontWeight={tab === 2 ? "500" : "400"}
            borderRadius={"10px"}
            color={tab === 2 ? "white" : "green.200"}
            bg={tab === 2 ? "green.200": "white"}
            padding={"4px"}
            _hover={{
              bg:"green.200",
              color:"white",
              transform: "scale(1.05)",
              transition: "transform 0.3s ease, background-color 0.3s ease, color 0.3s ease" // Добавляем transition к bg и color
            }}

          >
            {data.title5}
          </Box>
        </HStack>
        {tab === 1 ? (
          <>
            <Box color="grey.100" fontSize="sm" my="2rem">
              <RichText text={data.description2}/>
            </Box>
            <Swiper
              modules={[Navigation]}
              navigation={{
                nextEl: ".right",
                prevEl: ".left",
              }}
              spaceBetween={28}
              slidesPerView={3}
              scrollbar={{ draggable: true }}
              style={{ width: "100%", height: "100%", overflow: "visible" }}
            >
              {data.links?.map((slide, idx) => (
                <SwiperSlide
                  key={`slide-${slide.title}-${idx}`}
                  style={{ width: "100%", height: "100%" }}
                >
                  <YoutubeFrames
                    idx={idx}
                    src={slide.src}
                    title={slide.title}
                    thumbnail={slide.thumbnail}
                  />
                </SwiperSlide>
              ))}
              <Stack spacing="3rem" mt="2rem" direction="row">
                <Button variant="transparent" w="173px" as={Link} to={`/information-videos`}>
                  {data.buttonText_1}
                </Button>
                <Box className="left" as={Button}>
                  <LeftButton />
                </Box>
                <Box className="right" as={Button}>
                  <RightButton />
                </Box>
              </Stack>
            </Swiper>
          </>
        ) : (
            <>
              <Box color="grey.100" fontSize="sm" my="2rem">
                <RichText text={data.description3}/>
              </Box>
              <Swiper
                modules={[Navigation]}
                navigation={{
                  nextEl: ".right",
                  prevEl: ".left",
                }}
                spaceBetween={28}
                slidesPerView={3}
                scrollbar={{ draggable: true }}
                style={{ width: "100%", height: "100%", overflow: "visible" }}
              >
                {data.links_1?.map((slide, idx) => (
                  <SwiperSlide key={`slide-${slide.title}-${idx}`} style={{ width: "100%", height: "100%" }}>
                    <YoutubeFrames
                        idx={idx}
                        src={slide.src}
                        title={slide.title}
                        thumbnail={slide.thumbnail}
                    />
                  </SwiperSlide>
                ))}
                <Stack spacing="3rem" mt="2rem" direction="row">
                  <Button variant="transparent" w="173px" as={Link} to={`/information-videos`}>
                    {data.buttonText_1}
                  </Button>
                  <Box className="left" as={Button}>
                    <LeftButton />
                  </Box>
                  <Box className="right" as={Button}>
                    <RightButton />
                  </Box>
                </Stack>
              </Swiper>
            </>
        )}
      </Container>
    </Box>
  );
});

export default AboutOffice;
