import * as React from 'react';
import { Box, Text, Heading, Stack, Image } from '@chakra-ui/react';
import RichText from "../PrismicRichText";

const AboutProject = React.forwardRef(({ data }, ref) => {
  return (
    <Stack
      ref={ref}
      justifyContent="space-between"
      spacing="10"
      mb={{base: '3.75rem', sm: "6.25rem"}}
      mt={{base: '1.875rem', sm: "3.75rem"}}
      direction={{ base: "column", md: "row" }}
    >
      <Image
        objectFit="cover"
        w="500px"
        height={{base: '200px', sm: "552px"}}
        borderRadius="16px"
        src={data.image}
        />

      <Box maxW="600px">
        <Heading as="h3" fontSize="2xl">
          {data.title1}
        </Heading>
        <Box  fontSize="sm" mt="6">
          <RichText text={data.description}/>
        </Box>
      </Box>
    </Stack>
  );
});
export default AboutProject;
