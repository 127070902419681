import * as React from "react";
import CardSwiper from "../../components/cardSwiper";
import {
  Box,
  Heading,
  Container,
} from "@chakra-ui/react";

const w =
  typeof window !== "undefined" &&
  window.innerWidth;

const EventsSlide = React.forwardRef(
  ({ data }, ref) => {
    const { slideData } = data;
    return (
      <Box overflow="hidden" ref={ref}>
        <Container px="5" maxW="7xl">
          <Heading
            fontSize="2xl"
            mb="3.75rem"
          >
            {data.title2}
          </Heading>
          <CardSwiper
            href="financial-education-events"
            overflow="visible"
            product
            date={false}
            // moreButton
            from="financial-education"
            empty
            buttonText={
              data.buttonText_1
            }
            slides={slideData}
            perView={w < 481 ? 1.2 : 3}
            borderRadius="24px"
          />
        </Container>
      </Box>
    );
  }
);
export default EventsSlide;
