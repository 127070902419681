import * as React from "react";
import { graphql } from "gatsby";
import {
  Container,
  Heading,
} from "@chakra-ui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import AboutProject from "../../components/financialEducation/AboutProject";
import CompletingOnline from "../../components/financialEducation/CompletingOnline";
import EventsSlide from "../../components/financialEducation/EventsSlide";
import AboutOffice from "../../components/financialEducation/AboutOffice";
import CommentsPanel from "../../components/whatToExpect/CommentsPanel";
import InformationBooklets from "../../components/financialEducation/InfirmationBooklets";
import usePrismicFinancialEducation from "../../hooks/usePrismicFinancialEducation";
import usePrismicWhatToExpect from "../../hooks/usePrismicWhatToExpect";

const isBrowser = () =>
  typeof window !== "undefined";
const FinancialEducation = () => {
  const { language } = useTranslation();
  const data = usePrismicFinancialEducation();
  const data1 = usePrismicWhatToExpect();


  const section =
    isBrowser() &&
    window.location.hash.replace(
      "#",
      ""
    );
  const scrollToRef = (offsetTop) =>
    window.scrollTo(0, offsetTop);
  const offsetTop = (ref) =>
    ref.current.offsetTop - 150;

  const aboutProgramRef =
    React.useRef(null);
  const currentEventsRef =
    React.useRef(null);
  const infoBookletsRef =
    React.useRef(null);
  const infoVideosRef =
    React.useRef(null);

  React.useEffect(() => {
    if (
      section === "about-the-program"
    ) {
      scrollToRef(
        offsetTop(aboutProgramRef)
      );
    }
    if (section === "current-events") {
      scrollToRef(
        offsetTop(currentEventsRef)
      );
    }
    if (
      section === "informative-booklets"
    ) {
      scrollToRef(
        offsetTop(infoBookletsRef)
      );
    }
    if (
      section === "informative-videos"
    ) {
      scrollToRef(
        offsetTop(infoVideosRef)
      );
    }
  }, [section]);
  return (
    <Main>
      <Container px="5" maxW="7xl">
        <Seo
          title={data.navText}
          description={data.navText}
          llink={`https://www.fsm.am/${language}/financial-education`}
        />
        <SmallNav>
          {data.navText}
        </SmallNav>

        <Heading
          as="h1"
          fontSize={{
            base: "2xl",
            sm: "3xl",
          }}
          mt={{ base: "3", sm: "5" }}
        >
          {data.title}
        </Heading>
        <AboutProject
          data={data}
          ref={aboutProgramRef}
        />
      </Container>
      <EventsSlide
        data={data}
        ref={currentEventsRef}
      />
      <Container mb="8rem" px="5" maxW="7xl">
      <CommentsPanel data={data1}/>
      </Container>
      <CompletingOnline data={data} />
      <InformationBooklets
        data={data}
        ref={infoBookletsRef}
      />
      <AboutOffice
        data={data}
        ref={infoVideosRef}
      />
    
    </Main>
  );
};

export default FinancialEducation;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
