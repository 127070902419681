import * as React from 'react';
import {SimpleGrid, GridItem, Heading, Button, Stack, Box} from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import {A11y, Navigation, Scrollbar} from "swiper";
import CommentsCard from './CommentsCard'
import LeftButton from "../../assets/arrow.svg";
import RightButton from "../../assets/right.svg";


const CommentsPanel = ({ data }) => {
  return (
    <>
      <Heading
        as="h2"
        fontSize="2xl"
        textAlign="left"
        mt="6"
        mb="8">
          {data.commentsTitle}
      </Heading>
      <SimpleGrid
          w="100%"
          columns={{
              base: 1,
              sm: 2,
              lg: 2,
          }}
      >
          <GridItem colSpan={2} position="relative">
              <Swiper
                modules={[Navigation, Scrollbar, A11y]}
                spaceBetween={28}
                breakpoints={{
                    768: {
                        slidesPerView: 1,
                    },
                    769: {
                        slidesPerView: 3,
                    },

                }}
                navigation={{
                    nextEl: '.right',
                    prevEl: '.left'
                }}
                scrollbar={{ draggable: true }}
                style={{overflow: "hidden", width: '100%'}}
            >

                    {
                        data.comments?.map((item, idx) => (
                            <SwiperSlide key={`comments-${item.date}-${idx}`} style={{width: '100%', height: '100%'}}>
                                 <CommentsCard value={item}/>
                            </SwiperSlide>
                        ))
                    }
                <Stack
                    spacing="3rem"
                    mt="2rem"
                    direction="row"
                >
                    <Box className='left' as={Button}><LeftButton /></Box>
                    <Box className='right' as={Button}><RightButton /></Box>
                </Stack>
            </Swiper>
          </GridItem>
      </SimpleGrid>

    </>
  );
};
export default CommentsPanel;
